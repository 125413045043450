.community-page {
  /* padding: 3rem; */
  background-image: url('../../components/assets/images/バルコニー.png');
  background-size: cover;
  background-position: top;
   /* ヘッダーとフッターの高さが合計100pxと仮定 */
  min-height: calc(100vh - 100px);
  /* 中央に寄せる */
  padding: 4rem;

    
  }
  
  .community-page h1 {
    font-size: 2.3rem;
    margin-bottom: 1rem;
    color: #f4e3e3; /* ややグレーの白色 */
    text-shadow: 1px 1px 0px rgba(0,0,0,0.8), 2px 2px 10px rgba(0,0,0,0.5); /* 細かい黒の影とぼかしを追加 */
  
  }

  
  .create-post-button {
    display: inline-block;
    padding: 0.5rem 1rem;
    background-color: #007bff;
    color: #fff;
    text-decoration: none;
    border-radius: 4px;
    margin-bottom: 2rem;
    background-color: rgba(7, 15, 246, 0.601); /* 50%の透明度で黒 */
  }
  
  .post-list {
    display: grid;
    gap: 1rem;
    
  }
  
  .post-item {
    background-color: #fff;
    padding: 0.2rem;
    border-radius: 4px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
    background-color: rgba(194, 194, 194, 0.584); /* 50%の透明度で黒 */

  }
  
  .post-item h2 {
    margin-bottom: 0.2rem;
    font-size: 1.5rem;
  }
  
  .post-item p {
    margin-bottom: 0.2rem;
    color: #666;
  }