body {
  font-family: 'Arial', sans-serif;
  margin: 0;
  padding: 0;
  background-color: #f4f4f4;
  overflow-x: hidden;
}

/* App.css または global.css に追加 */
html,
body {
  height: 100%;
}

.app {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
  position: relative;
}

main {
  flex: 1;
  display: flex;
  flex-direction: column;
}

/* 他のスタイルは変更なし */

@media screen and (max-width: 768px) {
  main {
    padding: 0;
    /* モバイルでもパディングを0に */
  }
}
