:root {
  --primary-color: #4a90e2;
  --secondary-color: #34495e;
  --background-color: #f0f4f8;
  --text-color: #333;
  --error-color: #e74c3c;
  --success-color: #2ecc71;
}

.login-page {
  display: flex;
  justify-content: center;
  align-items: flex-start;
  /* centerからflex-startに変更 */
  background-image: url('../../components/assets/images/バルコニー.png');
  background-size: cover;
  background-position: center;
  background-attachment: fixed;
  /* 背景画像を固定 */
  flex: 1;
  min-height: 100%;
  /* vh単位を使用せず、flex: 1 に依存 */
  box-sizing: border-box;
  position: relative;
  padding: 5rem 1rem 2rem;
  /* 上部のパディングを増やして調整 */
}

.login-container {
  background-color: rgba(255, 255, 255, 0.9);
  backdrop-filter: blur(10px);
  padding: 2rem 3rem;
  border-radius: 1rem;
  box-shadow: 0 10px 25px rgba(0, 0, 0, 0.1);
  width: 100%;
  max-width: 400px;
  transition: transform 0.3s ease;
  margin-top: 2rem;
  /* 上部に余白を追加 */
}

.login-container:hover {
  transform: translateY(-5px);
}

h2 {
  text-align: center;
  color: var(--secondary-color);
  margin-bottom: 2rem;
  font-size: 2rem;
  font-weight: 700;
}

form {
  display: flex;
  flex-direction: column;
  gap: 1.5rem;
}

.form-group {
  position: relative;
}

label {
  display: block;
  margin-bottom: 0.5rem;
  color: var(--text-color);
  font-weight: 500;
}

input {
  width: 100%;
  padding: 0.75rem;
  border: 2px solid #e0e0e0;
  border-radius: 0.5rem;
  font-size: 1rem;
  transition: border-color 0.3s ease;
}

input:focus {
  outline: none;
  border-color: var(--primary-color);
}

button {
  background-color: var(--primary-color);
  color: white;
  padding: 1rem;
  border: none;
  border-radius: 0.5rem;
  font-size: 1rem;
  font-weight: 600;
  cursor: pointer;
  transition: background-color 0.3s ease, transform 0.3s ease;
}

button:hover {
  background-color: #3a7bd5;
  transform: translateY(-2px);
}

.error-message-container {
  width: 100%;
  margin: 10px 0;
  padding: 10px;
}

.error-message {
  color: #721c24;
  background-color: #f8d7da;
  border: 1px solid #f5c6cb;
  border-radius: 4px;
  padding: 10px 15px;
  margin: 0;
  text-align: center;
}

.google-login {
  margin-top: 2rem;
  text-align: center;
}

.google-login p {
  margin-bottom: 1rem;
  color: var(--text-color);
}

.google-auth-button {
  background-color: #fff;
  color: var(--text-color);
  border: 2px solid #e0e0e0;
  padding: 0.75rem;
  border-radius: 0.5rem;
  font-size: 1rem;
  font-weight: 500;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: background-color 0.3s ease, transform 0.3s ease;
}

.google-auth-button:hover {
  background-color: #f5f5f5;
  transform: translateY(-2px);
}

.google-auth-button img {
  width: 20px;
  height: 20px;
  margin-right: 0.75rem;
}

/* LoginPage.css に追加 */
.login-description {
  margin-bottom: 1.5rem;
  text-align: left;
  color: var(--text-color);
  font-size: 0.95rem;
  line-height: 1.5;
}

.login-description p {
  margin-bottom: 0.5rem;
}

/* 警告メッセージ用のスタイル追加 */
.login-description .login-warning {
  color: #e74c3c;
  /* 赤みがかった色 */
  font-weight: 600;
  font-size: 1.1em;
  margin-bottom: 1rem;
  padding: 0.5rem;
  background-color: rgba(231, 76, 60, 0.1);
  border-radius: 4px;
  text-align: center;
}

/* 区切り線のスタイルを更新 */
.divider {
  display: flex;
  align-items: center;
  text-align: center;
  margin: 2rem 0;
}

.divider::before,
.divider::after {
  content: '';
  flex: 1;
  border-bottom: 2px dashed #888;
  /* 点線に変更 */
}

.divider span {
  padding: 0.5rem 1rem;
  color: #666;
  font-size: 0.85rem;
  font-weight: 500;
  background-color: #f0f0f0;
  border-radius: 15px;
}

@media (max-width: 480px) {
  .login-page {
    padding-top: 60px;
    /* モバイルでは少し小さめに */
  }

  .login-container {
    padding: 2rem;
    border-radius: 0;
    box-shadow: none;
    margin: 10px 0;
  }

  h2 {
    font-size: 1.75rem;
  }

  input,
  button,
  .google-auth-button {
    font-size: 0.9rem;
  }
}

/* 管理用ログインセクションのスタイル */
.admin-login-section {
  margin-top: 2rem;
  padding: 1.5rem;
  background-color: rgba(240, 240, 240, 0.5);
  border-radius: 0.5rem;
  border-left: 3px solid #888;
  box-shadow: inset 0 0 10px rgba(0, 0, 0, 0.1);
  font-size: 0.9rem;
  /* フォントサイズを小さく */
}

/* 管理用フォームの入力フィールドとボタンのスタイル */
.admin-login-section .form-group input {
  background-color: rgba(255, 255, 255, 0.6);
  border: 1px solid #ccc;
  font-size: 0.9rem;
  /* ボタンのフォントサイズを小さく */
}

.admin-login-section button {
  background-color: #666;
  color: white;
}

.admin-login-section button:hover {
  background-color: #555;
}

/* ラベルのフォントサイズを小さく */
.admin-login-section label {
  font-size: 0.9rem;
}

/* エラーメッセージのフォントサイズを小さく */
.admin-login-section .error-message {
  font-size: 0.9rem;
}

/* 入力フィールドのフォントサイズを小さく */
.admin-login-section input {
  margin-top: 1rem;
  animation: slideDown 0.3s ease-out;
}

/* LoginPage.css に追加 */
.admin-toggle {
  cursor: pointer;
  user-select: none;
  transition: color 0.3s ease;
}

.admin-toggle:hover {
  color: var(--primary-color);
}

@keyframes slideDown {
  from {
    opacity: 0;
    transform: translateY(-10px);
  }

  to {
    opacity: 1;
    transform: translateY(0);
  }
}
