.question-detail-page {
  background-image: url('../../components/assets/images/壁と窓.jpg');
  background-size: cover;
  background-position: center;
  background-attachment: fixed;
  min-height: calc(100vh - 63px);
  /* フッターの高さを考慮 */
  padding: 4rem 2rem;
  padding-bottom: 6rem;
  /* フッター分の余白を確保 */
  display: flex;
  flex-direction: column;
  align-items: center;
  color: #ffffff;
  position: relative;
  /* 位置指定の基準点 */
}

.question-detail-container {
  background-color: rgba(0, 0, 0, 0.7);
  border-radius: 1rem;
  padding: 2rem;
  max-width: 800px;
  width: 100%;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  margin-bottom: 2rem;
  /* コンテナ下部の余白を追加 */
}


.question-detail-page p {
  font-size: 1.2rem;
  line-height: 1.6;
  margin-bottom: 2rem;
  background-color: rgba(107, 56, 201, 0.3);
  padding: 1.5rem;
  border-radius: 0.5rem;
  white-space: pre-wrap;
}

.choices {
  display: grid;
  gap: 1rem;
  margin-bottom: 2rem;
}

.choice {
  padding: 1rem;
  font-size: 1rem;
  background-color: rgba(255, 255, 255, 0.1);
  border: 2px solid rgba(255, 255, 255, 0.2);
  border-radius: 0.5rem;
  cursor: pointer;
  transition: all 0.3s ease;
  text-align: left;
}

.choice:hover {
  background-color: rgba(255, 255, 255, 0.2);
  transform: translateY(-2px);
}

.choice.selected {
  background-color: rgba(107, 56, 201, 0.5);
  border-color: #8a2be2;
}

/* 正解の選択肢のスタイルを追加 */
.choice.correct {
  background-color: rgba(0, 128, 0, 0.3);
  border-color: #90ee90;
}

/* 選択された不正解の選択肢のスタイルを調整 */
.choice.selected:not(.correct) {
  background-color: rgba(255, 0, 0, 0.3);
  border-color: #ffa07a;
}

.result {
  padding: 1rem;
  font-size: 1.2rem;
  font-weight: bold;
  margin-bottom: 1.5rem;
  text-align: center;
  border-radius: 0.5rem;
  animation: fadeIn 0.5s ease-out;
}

@keyframes fadeIn {
  from {
    opacity: 0;
  }

  to {
    opacity: 1;
  }
}

.result.correct {
  background-color: rgba(0, 128, 0, 0.3);
  color: #90ee90;
}

.result.incorrect {
  background-color: rgba(255, 0, 0, 0.3);
  color: #ffa07a;
}

.explanation {
  background-color: rgba(88, 77, 77, 0.3);
  padding: 1.5rem;
  margin-bottom: 2rem;
  border-radius: 0.5rem;
}

.explanation h2 {
  font-size: 1.5rem;
  margin-bottom: 1rem;
  color: #ffd700;
}

.explanation p {
  font-size: 1rem;
  line-height: 1.6;
  background-color: transparent;
  padding: 0;
  white-space: pre-wrap;
}

.navigation {
  display: flex;
  justify-content: space-between;
  margin-bottom: 2rem;
  width: 100%;
}

.navigation button,
.question-detail-page>button {
  padding: 0.75rem 1.5rem;
  font-size: 1rem;
  background-color: #8a2be2;
  color: #fff;
  border: none;
  border-radius: 2rem;
  cursor: pointer;
  transition: all 0.3s ease;
}

.navigation button:hover,
.question-detail-page>button:hover {
  background-color: #9932cc;
  transform: translateY(-2px);
}

.navigation button:disabled {
  background-color: #6c757d;
  cursor: not-allowed;
}

.question-detail-page>button {
  background-color: #6c757d;
  margin-top: 1rem;
}

.question-detail-page>button:hover {
  background-color: #5a6268;
}

/* 追加 */

.navigation-container {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 2rem;
  width: 100%;
}

.navigation-container.top-navigation {
  margin-bottom: 2rem;
  /* トップナビゲーションの下部マージンを増やす */
}

.nav-button {
  display: flex;
  align-items: center;
  padding: 0.75rem 1rem;
  font-size: 1rem;
  background-color: #8a2be2;
  color: #fff;
  border: none;
  border-radius: 2rem;
  cursor: pointer;
  transition: all 0.3s ease;
}

.nav-button:hover {
  background-color: #9932cc;
  transform: translateY(-2px);
}

.nav-button:disabled {
  background-color: #6c757d;
  cursor: not-allowed;
}

.nav-button.prev {
  margin-right: auto;
}

.nav-button.next {
  margin-left: auto;
}

.back-button {
  padding: 0.75rem 1.5rem;
  font-size: 1rem;
  background-color: #b607a7;
  color: #fff;
  border: none;
  border-radius: 2rem;
  cursor: pointer;
  transition: all 0.3s ease;
}

.back-button:hover {
  background-color: #5a6268;
  transform: translateY(-2px);
}

.question-image {
  margin: 2rem 0;
  text-align: center;
  background-color: rgba(0, 0, 0, 0.5);
  padding: 1rem;
  border-radius: 8px;
}

.question-image__img {
  max-width: 100%;
  max-height: 400px;
  border-radius: 4px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
}

/* 新しいカテゴリヘッダーのスタイル */
.category-header {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 1rem;
  margin-bottom: 2rem;
  padding: 1.5rem;
  /* パディングを少し増やす */
  background-color: rgba(107, 56, 201, 0.2);
  border-radius: 0.5rem;
  width: 100%;
  margin-top: 1rem;
  /* カテゴリヘッダーの上部マージンを追加 */
}

.category-info {
  display: flex;
  align-items: center;
  gap: 0.5rem;
}

.category-label {
  font-size: 1rem;
  color: #ffd700;
  font-weight: 600;
}

.category-value {
  font-size: 1.2rem;
  color: #ffffff;
  font-weight: bold;
}

.category-separator {
  color: #ffd700;
  font-size: 1.2rem;
  font-weight: bold;
}

.bottom-navigation {
  margin-top: 2rem;
  padding-top: 1.5rem;
  border-top: 1px solid rgba(255, 255, 255, 0.2);
  margin-bottom: 2rem;
  width: 100%;
}

/* フッターの上に表示されないようにする */
.footer {
  position: relative;
  z-index: 20;
  /* 下部ナビゲーションより上に表示 */
}






@media (max-width: 768px) {
  .question-detail-page {
    padding: 1rem 0.5rem;
    padding-bottom: 5rem;
    /* モバイルでのフッター分の余白 */
  }

  .question-detail-container {
    padding: 1rem;
    margin-bottom: 4rem;
    /* フッターとの余白を確保 */
  }

  /* スクロールの改善 */
  .question-detail-container {
    max-height: none;
    /* max-heightの制限を解除 */
    overflow: visible;
  }

  .question-detail-page h1 {
    font-size: 1.5rem;
    margin-bottom: 1rem;
  }

  .question-detail-page p {
    font-size: 0.9rem;
    padding: 1rem;
    margin-bottom: 1rem;
  }

  .bottom-navigation {
    position: fixed;
    /* stickyではなくfixedに変更 */
    bottom: 63px;
    /* フッターの高さ分上に配置 */
    left: 0;
    right: 0;
    background-color: rgba(0, 0, 0, 0.9);
    backdrop-filter: blur(5px);
    padding: 0.8rem;
    margin: 0;
    box-shadow: 0 -2px 10px rgba(0, 0, 0, 0.2);
    z-index: 10;
  }

  .choices {
    gap: 0.5rem;
  }

  .choice {
    padding: 0.75rem;
    font-size: 0.9rem;
  }

  .result {
    font-size: 1rem;
    padding: 0.75rem;
    margin-bottom: 1rem;
  }

  .explanation {
    margin-bottom: 6rem;
    /* 下部ナビゲーションとフッターの分の余白 */
  }

  .explanation h2 {
    font-size: 1.1rem;
  }

  .explanation p {
    font-size: 0.9rem;
  }

  .navigation {
    flex-direction: row;
    justify-content: space-between;
    gap: 0.5rem;
    margin-bottom: 1rem;
  }

  .navigation button,
  .question-detail-page>button {
    font-size: 0.8rem;
    padding: 0.5rem;
    width: auto;
    min-width: 80px;
  }
}

@media (max-width: 768px) {
  .navigation-container {
    flex-wrap: wrap;
    justify-content: center;
    gap: 0.5rem;
  }

  .navigation-container.top-navigation {
    margin-bottom: 1.5rem;
    /* モバイル時のマージンを調整 */
  }

  .nav-button {
    padding: 0.5rem;
    font-size: 0.8rem;
  }

  .button-text {
    display: none;
  }

  .back-button {
    order: -1;
    width: 100%;
    margin-bottom: 0.5rem;
  }
}

/* レスポンシブ対応 */
@media (max-width: 768px) {
  .category-header {
    flex-direction: column;
    gap: 0.5rem;
    padding: 1rem;
    margin-top: 0.75rem;
  }

  .category-separator {
    transform: rotate(90deg);
  }

  .category-info {
    width: 100%;
    justify-content: center;
  }

  .category-label {
    font-size: 0.9rem;
  }

  .category-value {
    font-size: 1rem;
  }
}
