/* ベーシックなリセットとレスポンシブデザインの基本 */
* {
  box-sizing: border-box;
}

body {
  margin: 0;
  padding: 0;
  font-family: Arial, sans-serif;
  font-size: 16px;
  line-height: 1.5;
}

/* App.css または global.css に追加 */
html,
body {
  height: 100%;
  margin: 0;
  padding: 0;
}

img {
  max-width: 100%;
  height: auto;
}

.app {
  display: flex;
  flex-direction: column;
  min-height: 100%;
}

main {
  flex: 1;
  padding: 0;
  /* 追加 */
  display: flex;
  flex-direction: column;
}

/* レスポンシブなコンテナ */
.container {
  width: 100%;
  padding-right: 15px;
  padding-left: 15px;
  margin-right: auto;
  margin-left: auto;
}

@media (min-width: 576px) {
  .container {
    max-width: 540px;
  }
}

@media (min-width: 768px) {
  .container {
    max-width: 720px;
  }
}

@media (min-width: 992px) {
  .container {
    max-width: 960px;
  }
}

@media (min-width: 1200px) {
  .container {
    max-width: 1140px;
  }
}

/* フレックスボックスのユーティリティクラス */
.flex-container {
  display: flex;
  flex-wrap: wrap;
}

/* その他の共通ユーティリティクラス */

footer {
  /* 追加 */
  flex-shrink: 0;
}
