.open-source-licenses {
  max-width: 800px;
  margin: 0 auto;
  padding: 5rem 2rem 2rem;
  /* トップのパディングを増やす */
  min-height: calc(100vh - 100px);
  /* フッターの高さを考慮 */
}

h1 {
  margin-bottom: 2rem;
  /* h1タグの下のマージンを増やす */
}

.search-input {
  width: 100%;
  padding: 0.5rem;
  margin-bottom: 1rem;
  font-size: 1rem;
}

.licenses-list {
  display: flex;
  flex-direction: column;
  gap: 1rem;
}

.license-item {
  border: 1px solid #e0e0e0;
  border-radius: 4px;
}

.license-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 1rem;
  background-color: #f5f5f5;
  cursor: pointer;
}

.license-header h3 {
  margin: 0;
}

.license-details {
  padding: 1rem;
  background-color: #ffffff;
}

.license-details pre {
  white-space: pre-wrap;
  word-wrap: break-word;
  background-color: #f9f9f9;
  padding: 1rem;
  border-radius: 4px;
}
