.admin-page {
    padding: 3rem;
  }
  
  .admin-navigation ul {
    display: flex;
    list-style: none;
    padding: 0;
    margin-bottom: 2rem;
  }
  
  .admin-navigation li {
    margin-right: 1rem;
    padding: 0.5rem 1rem;
    background-color: #f0f0f0;
    border-radius: 4px;
    cursor: pointer;
  }
  
  .admin-navigation li.active {
    background-color: #007bff;
    color: #fff;
  }
  
  .admin-content {
    /* 管理対象のリストやデータテーブルのスタイルを追加 */
  }